/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import "./App.css";
import { useSelector } from "react-redux";
import AdminHome from "./Components/Pages/AdminPanel/AdminHome/AdminHome";
import AdminPanelLogin from "./Components/Pages/AdminPanel/AdminPanelLogin/AdminPanelLogin";
import AdminAddCategory from "./Components/Pages/AdminPanel/AdminMasters/AdminAddCategory";
import AdminAddProductType from "./Components/Pages/AdminPanel/AdminMasters/AdminAddProductType";
import AdminAddCollection from "./Components/Pages/AdminPanel/AdminMasters/AdminAddCollection";
import AdminAddPurity from "./Components/Pages/AdminPanel/AdminMasters/AdminAddPurity";
import AdminAddBox from "./Components/Pages/AdminPanel/AdminMasters/AdminAddBox";
import AdminAddSupplier from "./Components/Pages/AdminPanel/AdminMasters/AdminAddSupplier";
import AdminInventory from "./Components/Pages/AdminPanel/AdminEcommerce/AdminInventory";
import AdminAddBulkProducts from "./Components/Pages/AdminPanel/AdminEcommerce/AdminAddBulkProducts";
import AdminInvoice from "./Components/Pages/AdminPanel/AdminTrading/AdminInvoice";
import AdminAllCustomers from "./Components/Pages/AdminPanel/AdminEcommerce/AdminAllCustomers";
import AdminRates from "./Components/Pages/AdminPanel/AdminSettings/AdminRates";
import AdminAllOrders from "./Components/Pages/AdminPanel/AdminEcommerce/AdminAllOrders";
import ProductDetails from "./Components/Pages/AdminPanel/AdminEcommerce/ProductDetails";
import AdminAllUnlabelledList from "./Components/Pages/AdminPanel/AdminEcommerce/AdminAllUnlabelledList";
import AdminPurchase from "./Components/Pages/AdminPanel/AdminTrading/AdminPurchase";
import AdminInvoiceEdit from "./Components/Pages/AdminPanel/AdminTrading/AdminInvoiceEdit";
import AdminAddEmployee from "./Components/Pages/AdminPanel/AdminMasters/AdminAddEmployee";
import AdminSaleReport from "./Components/Pages/AdminPanel/AdminReports/AdminSaleReport";
import AdminCreditNote from "./Components/Pages/AdminPanel/AdminEcommerce/AdminCreditNote";
import AdminPurchaseEntry from "./Components/Pages/AdminPanel/AdminTrading/AdminPurchaseEntry";
import AdminPurchasePayments from "./Components/Pages/AdminPanel/AdminTrading/AdminPurchasePayments";
import AdminAddStone from "./Components/Pages/AdminPanel/AdminMasters/AdminAddStone";
import AdminAddSku from "./Components/Pages/AdminPanel/AdminMasters/AdminAddSku";
import AdminLedgerMain from "./Components/Pages/AdminPanel/AdminAccounts/AdminLedgerMain";
import AdminSupplierAllPayments from "./Components/Pages/AdminPanel/AdminAccounts/Extra Pages/AdminSupplierAllPayments";
import AdminInvoicePayments from "./Components/Pages/AdminPanel/AdminTrading/AdminInvoicePayments";
import AdminDebitNote from "./Components/Pages/AdminPanel/AdminEcommerce/AdminDebitNote";
import AdminStockReport from "./Components/Pages/AdminPanel/AdminReports/AdminStockReport";
import CategoryNew from "./Components/Pages/AdminPanel/AdminEcommerce/CategoryNew";
import AdminCashReport from "./Components/Pages/AdminPanel/AdminReports/AdminCashReport";
import ExcelImport from "./Components/Other Functions/ExcelImport";

function App() {
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let isAuthenticated = "";
  if (adminLoggedIn.toString() === "1") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "2") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "3") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "4") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "5") {
    isAuthenticated = true;
  } else isAuthenticated = false;
  const userDetails = allStates.reducer1;

  const navigate = useNavigate();

  // Use useEffect to navigate to the '/' route when the component mounts
  useEffect(() => {
    navigate("/adminpanellogin");
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AdminPanelLogin />}></Route>
        <Route path="/adminpanellogin" element={<AdminPanelLogin />}></Route>
        <Route
          path="/adminhome"
          element={isAuthenticated ? <AdminHome /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_category"
          element={isAuthenticated ? <AdminAddCategory /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_employee"
          element={isAuthenticated ? <AdminAddEmployee /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_product_type"
          element={
            isAuthenticated ? <AdminAddProductType /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/add_collection"
          element={
            isAuthenticated ? <AdminAddCollection /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/add_purity"
          element={isAuthenticated ? <AdminAddPurity /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_box"
          element={isAuthenticated ? <AdminAddBox /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_supplier"
          element={isAuthenticated ? <AdminAddSupplier /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_customer"
          element={
            isAuthenticated ? <AdminAllCustomers /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/add_stone"
          element={isAuthenticated ? <AdminAddStone /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/add_sku"
          element={isAuthenticated ? <AdminAddSku /> : <AdminPanelLogin />}
        ></Route>

        <Route
          path="/inventory"
          element={isAuthenticated ? <AdminInventory /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/purchase"
          element={isAuthenticated ? <AdminPurchase /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/purchase_entry"
          element={
            isAuthenticated ? <AdminPurchaseEntry /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/ledger_main"
          element={isAuthenticated ? <AdminLedgerMain /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/unlabelled_list"
          element={
            isAuthenticated ? <AdminAllUnlabelledList /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/add_bulk_product"
          element={
            isAuthenticated ? <AdminAddBulkProducts /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/add_rates"
          element={isAuthenticated ? <AdminRates /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin_invoice"
          element={isAuthenticated ? <AdminInvoice /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin_invoice_edit"
          element={isAuthenticated ? <AdminInvoiceEdit /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin_orders"
          element={isAuthenticated ? <AdminAllOrders /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/product_details"
          element={isAuthenticated ? <ProductDetails /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin_sale_report"
          element={isAuthenticated ? <AdminSaleReport /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/credit_note"
          element={isAuthenticated ? <AdminCreditNote /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/debit_note"
          element={isAuthenticated ? <AdminDebitNote /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/purchase_payments"
          element={
            isAuthenticated ? <AdminPurchasePayments /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/receive_payments"
          element={
            isAuthenticated ? <AdminInvoicePayments /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/supplier_allpayments"
          element={
            isAuthenticated ? <AdminSupplierAllPayments /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/stock_report"
          element={isAuthenticated ? <AdminStockReport /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/cash_report"
          element={isAuthenticated ? <AdminCashReport /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/category_new"
          element={isAuthenticated ? <CategoryNew /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/import_excel"
          element={isAuthenticated ? <ExcelImport /> : <AdminPanelLogin />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
